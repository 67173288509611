import request from '@/utils/request'

async function sendRequestDownloadFile(data) {
  return await request({
    url: `https://api.cystack.net/portal/v1/public/library/send`,
    method: 'post',
    data,
  })
}

export default {
  sendRequestDownloadFile,
}

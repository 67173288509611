import axios from 'axios'
// import * as Sentry from "@sentry/nextjs";

const request = axios.create({
  withCredentials: false,
  timeout: 30000, // Set timeout to 5 seconds
  headers: {
    'Content-Type': 'application/json', // Set content type to JSON
  },
})

// Add a response interceptor
request.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response
  },
  (error) => {
    // Sentry.captureException(error);
    if (error.response) {
      console.error('Error status', error.response.status)
      console.error('Error data', error.response.data)
    } else if (error.request) {
      console.error('No response received', error.request)
    } else {
      console.error('Request error', error.message)
    }
    return Promise.reject(error)
  }
)

export default request

import ArrowRight from '@/public/icons/ArrowRight.svg'
import * as Sentry from '@sentry/nextjs'
import ToastSuccess from '@/public/icons/ToastSuccess.svg'
import XClose from '@/public/icons/XClose.svg'
import { Button, Form, Input, Modal, notification } from '@lockerpm/design'

import librarySercices from '@/services/library'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useState } from 'react'

export default function FormRegisterReport({ isOpen, onCloseModal }) {
  const { t } = useTranslation('blog')
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [registered, setRegistered] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const data = {
        email: values.email_work,
        fullname: values.fullname,
        job_title: values?.job_title || '',
        organization_name: values.organization_name || values.fullname,
        file_name: 'lo_lot_secrets_2024.pdf',
        source: 'landing_form',
        source_link: window.location.href,
      }
      await librarySercices.sendRequestDownloadFile(data)
      setRegistered(true)
      api.success({
        className: 'toast-success',
        message: 'Tài liệu đang được gửi đến bạn qua email',
        description: 'Nếu không nhận được email, bạn vui lòng kiểm tra hòm thư spam.',
        icon: <ToastSuccess />,
        closeIcon: <XClose />,
      })
      onCloseModal()
    } catch (error) {
      Sentry.captureMessage(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal open={isOpen} footer={null} onCancel={onCloseModal} className="relative top-8">
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        name="request-send-file"
        className="form-request-send-file"
      >
        <h2 className="mb-6 text-display-sm text-[#344054] font-semibold">
          {t('request_register_report.title')}
        </h2>
        <Image
          src={'/images/FormRegisterReport.svg'}
          width={460}
          height={390}
          className="w-full object-contain mb-6"
        />
        <div className="flex-1">
          <Form.Item
            name="fullname"
            rules={[
              {
                required: true,
                message: t('validate.input_required', {
                  ns: 'common',
                  field: t('form.fullname', { ns: 'common' }),
                }),
              },
            ]}
            className="mb-3 w-full"
          >
            <Input
              readOnly={registered}
              className="h-12 text-md font-medium"
              placeholder={t('form.fullname', { ns: 'common' })}
            />
          </Form.Item>
          <Form.Item
            name="email_work"
            rules={[
              {
                required: true,
                message: t('validate.input_required', {
                  ns: 'common',
                  field: t('form.email_work', { ns: 'common' }),
                }),
              },
              {
                type: 'email',
                message: t('validate.invalid', {
                  ns: 'common',
                  field: t('form.email_work', { ns: 'common' }),
                }),
              },
            ]}
            className="mb-3 w-full"
          >
            <Input
              readOnly={registered}
              className="h-12 text-md font-medium"
              placeholder={t('form.email_work', { ns: 'common' })}
            />
          </Form.Item>
          <Form.Item name="organization_name" className="mb-3 w-full">
            <Input
              readOnly={registered}
              className="h-12 text-md font-medium"
              placeholder={t('form.company', { ns: 'common' })}
            />
          </Form.Item>
          <Form.Item
            name="job_title"
            rules={[
              {
                required: true,
                message: t('validate.input_required', {
                  ns: 'common',
                  field: t('form.job_title', { ns: 'common' }),
                }),
              },
            ]}
            className="mb-3 w-full"
          >
            <Input
              readOnly={registered}
              className="h-12 text-md font-medium"
              placeholder={t('form.input_job_title', { ns: 'common' })}
            />
          </Form.Item>
        </div>
        <Form.Item className="mb-0">
          <Button
            size={'large'}
            type="primary"
            loading={loading}
            disabled={registered}
            className="text-md font-semibold py-2 h-12 rounded-lg flex items-center justify-center gap-2"
            htmlType="submit"
            block
          >
            <span>{t('request_register_report.register')}</span>
            <ArrowRight className="w-6" />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
